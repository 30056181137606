// Basic utility functions for the app

/**
 * Format a number as USD currency
 * @param {number} value - The value to format
 * @returns {string} Formatted currency string
 */
export function formatCurrency(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);
}

/**
 * Calculate the total value of a portfolio's assets
 * @param {Array} assets - Array of assets with currentValue property
 * @returns {number} Total portfolio value
 */
export function calculatePortfolioValue(assets) {
  return assets.reduce((total, asset) => total + (asset.currentValue || 0), 0);
} 