<template>
  <div id="app-layout">
    <div class="header-container">
      <AppHeader />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AppHeader from '../components/AppHeader.vue';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppHeader,
  },
});
</script>

<style scoped>
#app-layout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-grow: 1;
  background-color: #000;
  color: #fff;
  height: 100vh; /* Changed from min-height to fixed height */
  overflow: hidden; /* Prevent scrollbars */
}

.header-container {
  margin-top: 0;
}
</style>
