// store.js
import {createStore} from 'vuex';

export default createStore({
    state: {
        btcExchangeRate: 30000.0, // Default value
        ethExchangeRate: 2000.0, // Default value
        wasm: null, // WASM module instance
        wasmError: null, // Any WASM loading errors
        walletConnected: false, // Whether a wallet is connected
        walletSkipped: false // Whether the user skipped wallet connection
    },
    getters: {
        // Getter for btcExchangeRate
        getBtcExchangeRate: (state) => {
            return state.btcExchangeRate;
        },
        getEthExchangeRate: (state) => {
            return state.ethExchangeRate;
        },
        getWasm: (state) => {
            return state.wasm;
        },
        getWasmError: (state) => {
            return state.wasmError;
        },
        isWalletConnected: (state) => {
            return state.walletConnected;
        },
        isWalletSkipped: (state) => {
            return state.walletSkipped;
        },
        shouldShowWalletConnect: (state) => {
            return !state.walletConnected && !state.walletSkipped;
        }
    },
    mutations: {
        // Mutation (setter) for btcExchangeRate
        setBtcExchangeRate(state, rate) {
            state.btcExchangeRate = rate;
        },
        setEthExchangeRate(state, rate) {
            state.ethExchangeRate = rate;
        },
        setWasm(state, instance) {
            state.wasm = instance;
            state.wasmError = null;
        },
        setWasmError(state, error) {
            state.wasmError = error;
            state.wasm = null;
        },
        setWalletConnected(state, isConnected) {
            state.walletConnected = isConnected;
            if (isConnected) {
                state.walletSkipped = false;
            }
        },
        setWalletSkipped(state, isSkipped) {
            state.walletSkipped = isSkipped;
        }
    },
    actions: {
        // // Optional: Async action that could fetch and then commit the new rate
        // async fetchAndSetBtcExchangeRate({ commit }) {
        //     // Example using a fictional API endpoint
        //     // const response = await axios.get('https://api.example.com/btcRate');
        //     // commit('setBtcExchangeRate', response.data.rate);
        // }
    }
});
