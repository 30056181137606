<template>
  <div class="app-header">
    <div class="left-section">
      <router-link to="/app" class="logo">
        <img :src="require('@/assets/logo.png')" alt="Logo" class="logo-img">
        <h3>Redgold</h3>
      </router-link>
      <div class="dropdown">
        <a href="https://redgold.io" class="btn btn-outline-light dropdown-toggle website-btn" 
                type="button" 
                id="websiteDropdown" 
                aria-expanded="false">
          Website
        </a>
        <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
          <li><a class="dropdown-item" :href="getDocsUrl()" target="_blank">Docs</a></li>
          <li><a class="dropdown-item" href="https://discord.gg/86fzxJg8ce" target="_blank">Discord</a></li>
          <li><a class="dropdown-item" href="https://grafana.redgold.io/d/pj3zzDu4z/redgold?orgId=1&from=now-30m&to=now" target="_blank">Grafana</a></li>
          <li><a class="dropdown-item" href="https://blog.redgold.io" target="_blank">Blog</a></li>
          <li><router-link class="dropdown-item" to="/">Explorer</router-link></li>
        </ul>
      </div>
    </div>
    <div v-if="!isWalletConnected" class="wallet-status">
      No wallet connected, showing demo view
    </div>
    <div class="header-buttons">
      <router-link to="/" class="btn btn-outline-light me-2">Explorer</router-link>
      <button class="btn btn-gold" @click="connectWallet">Connect Wallet</button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AppHeader',
  setup() {
    const store = useStore();
    const router = useRouter();
    
    const isWalletConnected = computed(() => store.getters.isWalletConnected);

    const connectWallet = () => {
      // This would be replaced with actual wallet connection logic
      store.commit('setWalletConnected', true);
      
      // If we're on the connect wallet screen, redirect to app
      if (router.currentRoute.value.path === '/app/connect') {
        router.push('/app');
      }
    };

    const getDocsUrl = () => {
      let docsUrl = "https://docs.redgold.io";
      const hostname = window.location.hostname;

      if (hostname.includes('dev') || hostname.includes('localhost')) {
        docsUrl = "https://dev.docs.redgold.io";
      } else if (hostname.includes('staging')) {
        docsUrl = "https://staging.docs.redgold.io";
      } else if (hostname.includes('test')) {
        docsUrl = "https://test.docs.redgold.io";
      }

      return docsUrl;
    };

    return {
      isWalletConnected,
      connectWallet,
      getDocsUrl
    };
  }
});
</script>

<style scoped>
.app-header {
  background-color: #121212;
  border-bottom: 1px solid #333;
  height: 60px; /* Increased height for better spacing */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  position: relative;
}

.wallet-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 165, 0, 0.2);
  color: #ffa500;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: 500;
  z-index: 10;
}

.left-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  height: 100%;
  margin-right: 1rem;
  padding-bottom: 2px; /* Further reduced bottom padding */
}

.logo:hover {
  color: #ADD8E6;
  background-color: transparent;
}

.logo-img {
  
  height: 45px; /* Increased from 32px */
  width: 45px; /* Increased from 32px */
  margin-right: 12px;
  margin-top: 10px;
  margin-bottom: 0; /* Removed bottom margin to reduce space */
}

.logo h3 {
  padding-top: 10px;
  margin: 0;
  font-size: 1.4rem; /* Larger text */
  line-height: 1.2;
}

.header-buttons {
  padding-top: 9px;
  display: flex;
  align-items: center;
  height: 100%;
}

/* Adjust button sizes and ensure vertical centering */
.btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 38px; /* Fixed height to match with the logo */
}

/* Add specific margin between buttons */
.me-2 {
  margin-right: 0.5rem;
}

/* Custom gold button styling */
.btn-gold {
  background-color: #8b6914; /* Even darker gold color */
  color: #fff;
  border: 1px solid #7a5c11;
}

.btn-gold:hover {
  background-color: #7a5c11;
  color: #fff;
  border-color: #69500e;
}

/* Dropdown styling */
.dropdown {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 19px;
  padding-left: 2px;
}

/* Specific styling for the website button to fix vertical alignment */
.website-btn {
  margin-top: 10px; /* Add margin to the top to push it down */
  margin-bottom: 10px; /* Balance with margin at the bottom */
}


.dropdown-toggle::after {
  margin-left: 0.5em;
  vertical-align: middle;
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  background-color: #1e1e1e;
  border: 1px solid #333;
  min-width: 8rem; /* Reduced width */
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
}

.dropdown-item {
  color: #fff;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #333;
  color: #fff;
}
</style>
