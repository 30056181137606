<template>
  <div class="wallet-connect-screen">
    <div class="container">
      <div class="connect-box">
        <h2 class="connect-title">Connect wallet to view Portfolio</h2>
        <div class="button-group">
          <button class="btn btn-primary connect-btn" @click="connectWallet">Connect</button>
          <button class="btn btn-outline-secondary skip-btn" @click="skipConnection">Skip for now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'WalletConnectScreen',
  setup() {
    const router = useRouter();
    const store = useStore();

    const connectWallet = () => {
      // This would be replaced with actual wallet connection logic
      // For now, just set the state and redirect
      store.commit('setWalletConnected', true);
      router.push('/app');
    };

    const skipConnection = () => {
      // Skip wallet connection but mark as not connected
      store.commit('setWalletSkipped', true);
      router.push('/app');
    };

    return {
      connectWallet,
      skipConnection
    };
  }
});
</script>

<style scoped>
.wallet-connect-screen {
  height: calc(100vh - 60px); /* Changed from min-height to height to prevent overflow */
  display: flex;
  align-items: flex-start; /* Changed from center to flex-start to move box higher */
  justify-content: center;
  padding-top: 15vh; /* Add padding-top to position the box at a specific height */
  background-color: #000;
  color: #fff;
  overflow: hidden; /* Prevent scrollbar */
}

.connect-box {
  background-color: rgba(30, 30, 30, 0.5);
  border: 1px solid #333;
  border-radius: 8px;
  padding: 3rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.connect-title {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.connect-btn {
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #8b6914; /* Even darker gold color to match header button */
  border-color: #7a5c11;
}

.connect-btn:hover {
  background-color: #7a5c11;
  border-color: #69500e;
}

.skip-btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #aaa;
  border-color: #555;
}

.skip-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
</style>
