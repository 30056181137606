<template>
  <div class="app-screen">
    <div class="container mt-4">
      <div class="row">
        <div class="col-12">
          <h1>Redgold App Dashboard</h1>
          <p>Welcome to the Redgold application. This is your dashboard for managing portfolio contracts.</p>
          
          <div class="card bg-dark text-white mt-4">
            <div class="card-header d-flex justify-content-between align-items-center">
              <span>Portfolio Overview</span>
              <button class="btn btn-primary btn-sm" @click="createNewPortfolio">Create New Portfolio</button>
            </div>
            <div class="card-body">
              <div v-if="portfolios.length === 0" class="text-center py-4">
                <p>You don't have any portfolios yet.</p>
                <p>Create your first portfolio to get started.</p>
              </div>
              <div v-else>
                <div v-for="portfolio in portfolios" :key="portfolio.id" class="portfolio-item mb-3 p-3 border border-secondary rounded">
                  <h5>{{ portfolio.name }}</h5>
                  <p>Created: {{ formatDate(portfolio.createdAt) }}</p>
                  <p>Total Value: {{ formatCurrency(calculatePortfolioValue(portfolio.assets)) }}</p>
                  <div class="d-flex justify-content-end">
                    <router-link :to="`/app/portfolio/${portfolio.id}`" class="btn btn-outline-light btn-sm">View Details</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { formatCurrency, calculatePortfolioValue } from '../utils/app-utils.js';

export default defineComponent({
  name: 'AppScreen',
  setup() {
    // Sample data - in a real app, this would come from an API
    const portfolios = ref([
      {
        id: '1',
        name: 'Balanced Growth Portfolio',
        createdAt: new Date(2023, 0, 15),
        assets: [
          { symbol: 'BTC', network: 'Bitcoin', allocation: 40, currentValue: 24000 },
          { symbol: 'ETH', network: 'Ethereum', allocation: 30, currentValue: 18000 },
          { symbol: 'SOL', network: 'Solana', allocation: 20, currentValue: 12000 },
          { symbol: 'USDC', network: 'Ethereum', allocation: 10, currentValue: 6000 }
        ],
        rebalanceRules: [
          { type: 'threshold', threshold: 5 }
        ]
      }
    ]);

    const createNewPortfolio = () => {
      // In a real app, this would open a modal or navigate to a creation page
      console.log('Creating new portfolio');
    };

    const formatDate = (date) => {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    return {
      portfolios,
      createNewPortfolio,
      formatDate,
      formatCurrency,
      calculatePortfolioValue
    };
  }
});
</script>

<style scoped>
.app-screen {
  min-height: calc(100vh - 70px);
}

.card {
  border: 1px solid #333;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #333;
}

.portfolio-item {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.portfolio-item:hover {
  background-color: rgba(30, 30, 30, 0.5);
}
</style> 